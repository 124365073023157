import { useMotionValue } from "framer-motion";
import { useEffect, useRef } from "react";

const useMouseDistance = () => {
  const ref = useRef<HTMLDivElement>(null);

  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);

  // The distance between the mouse and the element
  const distance = useMotionValue(1000);

  useEffect(() => {
    function handleMouseMove(event: globalThis.MouseEvent) {
      if (!ref.current) {
        return;
      }

      const { left, top, width, height } = ref.current.getBoundingClientRect();
      const x = event.clientX - left - width / 2;
      const y = event.clientY - top - height / 2;

      const dist = Math.sqrt(x * x + y * y); // calculate distance to center

      mouseX.set(x);
      mouseY.set(y);
      distance.set(dist);
    }

    function handleMouseLeave() {
      mouseX.set(0);
      mouseY.set(0);
      distance.set(1000);
    }

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseout", handleMouseLeave);
    document.addEventListener("lostpointercapture", handleMouseLeave);

    // Cleanup function to remove the event listeners when the component unmounts
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseout", handleMouseLeave);
      document.removeEventListener("lostpointercapture", handleMouseLeave);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  return {
    mouseX,
    mouseY,
    distance,
    ref,
  };
};

export default useMouseDistance;
