import { motion, useSpring, useTransform } from "framer-motion";

import useMouseDistance from "./useMouseDistance.js";
import useRotator from "./useRotator.js";

interface Props {
  variant?: "light" | "dark";
  size?: number;
  /**
   * The maximum rotation / angle of the windmill
   * @default 35
   */
  maxRotation?: number;
  /**
   * The minimum rotation / angle of the windmill
   * @default 5
   */
  minRotation?: number;
  /**
   * The distance from the center of the windmill that the mouse can be to have an effect
   * @default 500
   */
  magnitudeRadius?: number;
  /**
   * Spin speed at the magnitude radius of the windmill
   * @default 25
   */
  minSpinSpeed?: number;
  /**
   * Maximum spin speed near the center of the windmill
   * @default 100
   */
  maxSpinSpeed?: number;

  /**
   * The spin speed when the mouse is outside of the radius
   * @default 1
   */
  outOfRadiusSpinSpeed?: number;

  /**
   * The duration of the rotation animation. The longer the duration the more the
   * windmill feels heavy as it rotates slower
   * @default 3000
   */
  rotationDuration?: number;
}

/**
 * Functional component to render a rotating windmill effect based on mouse movement.
 * The windmill's rotation speed and angle are influenced by the mouse's distance to the windmill.
 *
 * @param {Props} props The component props
 * @returns {JSX.Element} The RotatingWindmill component
 * @author @mxs2019
 */
const RotatingWindmill = ({
  variant = "dark",
  size = 96,
  maxRotation = 35,
  minRotation = 5,
  magnitudeRadius = 500,
  minSpinSpeed = 25,
  maxSpinSpeed = 100,
  outOfRadiusSpinSpeed = 1,
  rotationDuration = 3000,
}: Props) => {
  // Keep track of where the mouse is relative to the center windmill
  const { mouseX, mouseY, distance, ref } = useMouseDistance();

  /**
   *  Determines the spin speed based on the mouse's distance to the windmill center
   * Closer to the center increases the spin speed. The spin speed is the same
   * once you are inside the radius of the windmill
   */
  const spinSpeed = useTransform(
    distance,
    [0, size / 2, magnitudeRadius, magnitudeRadius + 1, 10000],
    [maxSpinSpeed, maxSpinSpeed, minSpinSpeed, outOfRadiusSpinSpeed, outOfRadiusSpinSpeed]
  );

  // Uses the calculated spin speed to rotate the windmill
  const rotate = useRotator(spinSpeed);

  // Calculates the rotation magnitude based on the mouse's distance
  const rotationMagnitude = useTransform(
    distance,
    [magnitudeRadius, size / 3, 0],
    [minRotation, maxRotation, 0]
  );

  // Applies a spring animation for the rotateX to smooth the rotation
  const rotateX = useSpring(
    useTransform(mouseY, (y) => (y / distance.get()) * -rotationMagnitude.get()),
    { bounce: 0, duration: rotationDuration }
  );

  // Applies a spring animation for the rotateY to smooth the rotation
  const rotateY = useSpring(
    useTransform(mouseX, (x) => (x / distance.get()) * rotationMagnitude.get()),
    { bounce: 0, duration: rotationDuration }
  );

  return (
    <div>
      <div ref={ref} className="group pointer-events-none relative z-50">
        <motion.div
          style={{
            rotateX,
            rotateY,
            transformPerspective: 100,
          }}
        >
          <motion.img
            style={{
              width: size,
              height: size,
              rotate,
            }}
            src={variant === "dark" ? "/logo-black.svg" : "/logo-white.svg"}
            alt="Windmill"
          />
        </motion.div>
      </div>
    </div>
  );
};

export default RotatingWindmill;
