import { useAnimationFrame, useMotionValue, useSpring, type MotionValue } from "framer-motion";

/**
 * Custom hook to manage the rotation of the windmill based on the fan speed.
 * This hook utilizes framer-motion's spring and motion value to smoothly
 * transition the windmill's rotation speed in response to changes in fan speed.
 *
 * @returns A MotionValue representing the current rotation of the windmill.
 * @author @mxs2019
 */
const useRotator = (fanSpeed: MotionValue<number>) => {
  // Utilizes a spring animation to smoothly transition fan speed changes.
  const fanSpeedSpring = useSpring(fanSpeed, { bounce: 0, duration: 10 });

  // MotionValue to track and update the windmill's rotation.
  const rotate = useMotionValue(0);

  // Continuously updates the rotation value based on the current fan speed.
  useAnimationFrame((t, delta) => {
    const timeSinceLastFrame = delta;
    const fanSpeedSpringValue = fanSpeedSpring.get();

    // Skips rotation update if the fan speed is 0 to prevent unnecessary calculations.
    if (fanSpeedSpringValue === 0) {
      return;
    }

    // Calculates the new rotation value based on the time since the last frame and the current fan speed.
    const newRotateDiff = timeSinceLastFrame / 10 + fanSpeedSpring.get() / 20;
    rotate.set(rotate.get() + newRotateDiff);
  });

  return rotate;
};

export default useRotator;
